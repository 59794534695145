/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import e from "../core/Error.js";
import { JSONSupport as r } from "../core/JSONSupport.js";
import { property as o } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { subclass as s } from "../core/accessorSupport/decorators/subclass.js";
import l from "./PortalFolder.js";
import i from "./PortalGroup.js";
var n;
let u = n = class extends r {
  constructor(t) {
    super(t), this.access = null, this.created = null, this.culture = null, this.description = null, this.email = null, this.fullName = null, this.modified = null, this.orgId = null, this.portal = null, this.preferredView = null, this.privileges = null, this.region = null, this.role = null, this.roleId = null, this.sourceJSON = null, this.units = null, this.username = null, this.userLicenseTypeId = null, this.userType = null;
  }
  get thumbnailUrl() {
    const t = this.url,
      e = this.thumbnail;
    return t && e ? this.portal.normalizeUrl(`${t}/info/${e}?f=json`) : null;
  }
  get userContentUrl() {
    const t = this.portal?.restUrl;
    return t ? `${t}/content/users/${this.username}` : null;
  }
  get url() {
    const t = this.portal?.restUrl;
    return t ? `${t}/community/users/${this.username}` : null;
  }
  addItem(t) {
    const e = t && t.item,
      r = t?.data,
      o = t?.folder,
      s = {
        method: "post"
      };
    e && (s.query = e.createPostQuery(), null != r && ("string" == typeof r ? s.query.text = r : "object" == typeof r && (s.query.text = JSON.stringify(r))));
    let l = this.userContentUrl;
    return o && (l += "/" + ("string" == typeof o ? o : o.id)), this.portal.request(l + "/addItem", s).then(t => (e.id = t.id, e.portal = this.portal, e.loaded ? e.reload() : e.load()));
  }
  async deleteItem(t, e = !1) {
    let r = this.userContentUrl;
    t.ownerFolder && (r += "/" + t.ownerFolder);
    const o = e ? {
      permanentDelete: !0
    } : {};
    await this.portal.request(r + `/items/${t.id}/delete`, {
      method: "post",
      query: o
    }), t.id = null, t.portal = null;
  }
  async deleteItems(t, r = !1) {
    t = t.slice();
    const o = this.userContentUrl + "/deleteItems",
      s = [],
      l = t.map(t => t.id);
    if (l.length) {
      const i = {
          method: "post",
          query: {
            items: l.join(","),
            permanentDelete: r
          }
        },
        n = await this.portal.request(o, i);
      for (const r of n.results) {
        const o = t.find(t => r.itemId === t.id),
          l = r.success;
        let i = null;
        l ? (o.id = null, o.portal = null) : r.error && (i = new e("portal:delete-item-failed", r.error.message, r.error)), s.push({
          item: o,
          success: l,
          error: i
        });
      }
    }
    return s;
  }
  fetchFolders() {
    const t = {
      query: {
        num: 1
      }
    };
    return this.portal.request(this.userContentUrl ?? "", t).then(t => {
      let e;
      return e = t && t.folders ? t.folders.map(t => {
        const e = l.fromJSON(t);
        return e.portal = this.portal, e;
      }) : [], e;
    });
  }
  fetchGroups() {
    return this.portal.request(this.url ?? "").then(t => {
      let e;
      return e = t && t.groups ? t.groups.map(t => {
        const e = i.fromJSON(t);
        return e.portal = this.portal, e;
      }) : [], e;
    });
  }
  async fetchItems(t) {
    t ??= {};
    let e = this.userContentUrl ?? "";
    t.folder && (e += "/" + t.folder.id);
    const {
        default: r
      } = await import("./PortalItem.js"),
      o = {
        folders: !1,
        inRecycleBin: !!t.inRecycleBin || null,
        foldersContent: !(t.folder || !t.includeSubfolderItems) || null,
        num: t.num || 10,
        start: t.start || 1,
        sortField: t.sortField || "created",
        sortOrder: t.sortOrder || "asc"
      },
      s = await this.portal.request(e, {
        query: o
      });
    let l;
    return s?.items ? (l = s.items.map(t => {
      const e = r.fromJSON(t);
      return e.portal = this.portal, e;
    }), await Promise.all(l.map(t => t.load())), {
      items: l,
      nextStart: s.nextStart,
      total: s.total
    }) : {
      items: [],
      nextStart: -1,
      total: 0
    };
  }
  fetchTags() {
    return this.portal.request(this.url + "/tags").then(t => t.tags);
  }
  getThumbnailUrl(t) {
    let e = this.thumbnailUrl;
    return e && t && (e += `&w=${t}`), e;
  }
  queryFavorites(t) {
    return this.favGroupId ? (this._favGroup || (this._favGroup = new i({
      id: this.favGroupId,
      portal: this.portal
    })), this._favGroup.queryItems(t)) : Promise.reject(new e("internal:unknown", "Unknown internal error", {
      internalError: "Unknown favGroupId"
    }));
  }
  async restoreItem(t, e) {
    const r = this.userContentUrl,
      o = e ? {
        folderID: "string" == typeof e ? e : e.id
      } : null;
    await this.portal.request(r + `/items/${t.id}/restore`, {
      method: "post",
      query: o
    });
  }
  toJSON() {
    throw new e("internal:not-yet-implemented", "PortalUser.toJSON is not yet implemented");
  }
  static fromJSON(t) {
    if (!t) return null;
    if (t.declaredClass) throw new Error("JSON object is already hydrated");
    const e = new n({
      sourceJSON: t
    });
    return e.read(t), e;
  }
};
t([o()], u.prototype, "access", void 0), t([o({
  type: Date
})], u.prototype, "created", void 0), t([o()], u.prototype, "culture", void 0), t([o()], u.prototype, "description", void 0), t([o()], u.prototype, "email", void 0), t([o()], u.prototype, "favGroupId", void 0), t([o()], u.prototype, "fullName", void 0), t([o({
  type: Date
})], u.prototype, "modified", void 0), t([o()], u.prototype, "orgId", void 0), t([o()], u.prototype, "portal", void 0), t([o()], u.prototype, "preferredView", void 0), t([o()], u.prototype, "privileges", void 0), t([o()], u.prototype, "region", void 0), t([o()], u.prototype, "role", void 0), t([o()], u.prototype, "roleId", void 0), t([o()], u.prototype, "sourceJSON", void 0), t([o()], u.prototype, "thumbnail", void 0), t([o({
  readOnly: !0
})], u.prototype, "thumbnailUrl", null), t([o()], u.prototype, "units", void 0), t([o({
  readOnly: !0
})], u.prototype, "userContentUrl", null), t([o({
  readOnly: !0
})], u.prototype, "url", null), t([o()], u.prototype, "username", void 0), t([o()], u.prototype, "userLicenseTypeId", void 0), t([o()], u.prototype, "userType", void 0), u = n = t([s("esri.portal.PortalUser")], u);
const p = u;
export { p as default };