/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import r from "../core/Error.js";
import { JSONSupport as e } from "../core/JSONSupport.js";
import { property as o } from "../core/accessorSupport/decorators/property.js";
import { ensureType as s } from "../core/accessorSupport/ensureType.js";
import "../core/RandomLCG.js";
import "../core/has.js";
import { subclass as n } from "../core/accessorSupport/decorators/subclass.js";
import i from "./PortalQueryParams.js";
var l;
let u = l = class extends e {
  constructor(t) {
    super(t), this.access = null, this.created = null, this.description = null, this.id = null, this.isInvitationOnly = !1, this.modified = null, this.owner = null, this.portal = null, this.snippet = null, this.sortField = null, this.sortOrder = null, this.sourceJSON = null, this.tags = null, this.title = null;
  }
  get thumbnailUrl() {
    const t = this.url,
      r = this.thumbnail;
    return t && r && this.portal ? this.portal?.normalizeUrl(`${t}/info/${r}?f=json`) : null;
  }
  get url() {
    const t = this.portal?.restUrl;
    return t ? t + "/community/groups/" + this.id : null;
  }
  fetchCategorySchema(t) {
    return this.portal.request(this.url + "/categorySchema", t).then(r => {
      const e = r.categorySchema || [];
      return e.some(t => "contentCategorySetsGroupQuery.LivingAtlas" === t.source) ? this._fetchCategorySchemaSet("LivingAtlas", t) : e;
    });
  }
  fetchMembers(t) {
    return this.portal.request(this.url + "/users", t);
  }
  getThumbnailUrl(t) {
    let r = this.thumbnailUrl;
    return r && t && (r += `&w=${t}`), r;
  }
  toJSON() {
    throw new r("internal:not-yet-implemented", "PortalGroup.toJSON is not yet implemented");
  }
  static fromJSON(t) {
    if (!t) return null;
    if (t.declaredClass) throw new Error("JSON object is already hydrated");
    const r = new l({
      sourceJSON: t
    });
    return r.read(t), r;
  }
  queryItems(t, r) {
    let e = s(i, t);
    const o = this.portal;
    return parseFloat(o.currentVersion) > 5 ? (e = e || new i(), o.queryPortal(`/content/groups/${this.id}/search`, e, "PortalItem", r)) : (e = e ? e.clone() : new i(), e.query = "group:" + this.id + (e.query ? " " + e.query : ""), o.queryItems(e, r));
  }
  _fetchCategorySchemaSet(t, e) {
    const o = this.portal;
    return o.fetchSelf(o.authMode, !0, e).then(t => {
      const s = t.contentCategorySetsGroupQuery;
      if (s) {
        const t = new i({
          disableExtraQuery: !0,
          num: 1,
          query: s
        });
        return o.queryGroups(t, e);
      }
      throw new r("portal-group:fetchCategorySchema", "contentCategorySetsGroupQuery value not found");
    }).then(o => {
      if (o.total) {
        const r = o.results[0],
          s = new i({
            num: 1,
            query: `typekeywords:"${t}"`
          });
        return r.queryItems(s, e);
      }
      throw new r("portal-group:fetchCategorySchema", "contentCategorySetsGroupQuery group not found");
    }).then(t => {
      if (t.total) {
        return t.results[0].fetchData("json", e).then(t => {
          const r = t?.categorySchema;
          return r?.length ? r : [];
        });
      }
      return [];
    });
  }
};
t([o()], u.prototype, "access", void 0), t([o({
  type: Date
})], u.prototype, "created", void 0), t([o()], u.prototype, "description", void 0), t([o()], u.prototype, "id", void 0), t([o()], u.prototype, "isInvitationOnly", void 0), t([o({
  type: Date
})], u.prototype, "modified", void 0), t([o()], u.prototype, "owner", void 0), t([o()], u.prototype, "portal", void 0), t([o()], u.prototype, "snippet", void 0), t([o()], u.prototype, "sortField", void 0), t([o()], u.prototype, "sortOrder", void 0), t([o()], u.prototype, "sourceJSON", void 0), t([o()], u.prototype, "tags", void 0), t([o()], u.prototype, "thumbnail", void 0), t([o({
  readOnly: !0
})], u.prototype, "thumbnailUrl", null), t([o()], u.prototype, "title", void 0), t([o({
  readOnly: !0
})], u.prototype, "url", null), u = l = t([n("esri.portal.PortalGroup")], u);
const p = u;
export { p as default };